<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-query`"
        v-model="properties.query"
        outlined
        dense
        :label="$lang.labels.query"
        data-cy="foreach-query"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        @input="handleChange('query', $event)"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-recordName`"
        v-model="properties.recordName"
        outlined
        dense
        :label="$lang.labels.recordName"
        data-cy="foreach-recordName"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        @input="handleChange('recordName', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import StepMixin from './StepMixin'

export default {
  name: 'ForeachStep',
  mixins: [StepMixin]
}
</script>
